import { t } from '@lingui/macro'
import { link } from '@/helper/link'
import { getBusinessName } from '@/helper/common'
import { UserAccountTypeEnum, UserModuleDescribeKeyEnum } from '@/constants/user'
import { baseUserStore } from '@/store/user'
import { TabsTypeLogRegisterType } from '@/features/user/log-register-modal/component/log-type-tabs'

export function getUserPageDefaultDescribeMeta(pageTitle: string, key: string) {
  const values = {
    businessName: getBusinessName(),
  }

  const getId = () => {
    switch (key) {
      case UserModuleDescribeKeyEnum.default:
        return `modules_assets_company_verified_material_index_page_server_efre42ngx6`
      case UserModuleDescribeKeyEnum.register:
        return `modules_user_register_index_page_ojyzttck8o`
      case UserModuleDescribeKeyEnum.agentCenter:
        return `helper_agent_index_gr1uz7jkp0`
      default:
        return ``
    }
  }

  return {
    title: pageTitle,
    description: t({
      id: getId(),
      values,
    }),
  }
}

/** 处理在点击登录或注册时需要打开弹框 */
export function handleLogRegisterRouter(type?: TabsTypeLogRegisterType, isReset?: boolean) {
  const { setLogOrRegister, setIsResetPassword, setLogRegisterVisible } = baseUserStore.getState()
  if (isReset) {
    setIsResetPassword(true)
  } else {
    setLogOrRegister(type || TabsTypeLogRegisterType.log)
  }
  link('/')
  setLogRegisterVisible(true)
}

/**
 * 判断当前用户是否试玩账号
 */
export function getIsDemoUser() {
  const { userInfo, isLogin } = baseUserStore.getState()

  return isLogin && userInfo?.memberTag === UserAccountTypeEnum.demoUser
}
